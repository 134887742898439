"use client";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselIndicator,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";

import React from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";
import { useTranslations } from "next-intl";

interface TestimoniItemType {
  message: string;
  name: string;
  unit: string;
  image: string;
  mode?: mode;
}

type mode = "gray" | "white";
interface TestimonialPropsType {
  mode?: mode;
}
import { motion } from "framer-motion";

export default function Testimonial(props: TestimonialPropsType) {
  const plugin = React.useRef(
    Autoplay({ delay: 2000, stopOnInteraction: true })
  );
  const { mode = "gray" } = props;
  const isWhite = mode == "white";

  const t = useTranslations("Homepage.Testimonial");
  const tTestimonial = useTranslations("Homepage.Testimonial.testimonialItems");

  const testimoni: TestimoniItemType[] = [
    {
      message: tTestimonial("BuIsmi.message"),
      name: "Bu Ismi",
      unit: tTestimonial("BuIsmi.unit"),
      image: "/assets/testimoni/bu-ismi.png",
    },
    {
      message: tTestimonial("BuSri.message"),
      name: "Bu Sri Handayani",
      unit: tTestimonial("BuSri.unit"),
      image: "/assets/testimoni/bu-sri-handayani.png",
    },
    {
      message: tTestimonial("MasShidiq.message"),
      name: "Mas Shidiq",
      unit: tTestimonial("MasShidiq.unit"),
      image: "/assets/testimoni/mas-shidiq.png",
    },
    {
      message: tTestimonial("MbaDinda.message"),
      name: "Mba Dinda",
      unit: tTestimonial("MbaDinda.unit"),
      image: "/assets/testimoni/mba-dinda.png",
    },
    {
      message: tTestimonial("PakTri.message"),
      name: "Pak Tri",
      unit: tTestimonial("PakTri.unit"),
      image: "/assets/testimoni/pak-tri.png",
    },
    {
      message: tTestimonial("PakUmar.message"),
      name: "Pak Umar",
      unit: tTestimonial("PakUmar.unit"),
      image: "/assets/testimoni/pak-umar-new.png",
    },
    {
      message: tTestimonial("PakWono.message"),
      name: "Pak Wono",
      unit: tTestimonial("PakWono.unit"),
      image: "/assets/testimoni/pak-wono.png",
    },
  ];

  return (
    <motion.section
      initial={{ opacity: 0 }}
      viewport={{ once: true }}
      whileInView={{ opacity: 1 }}
      className={cn(
        "text-[#101828] text-xs",
        !isWhite ? "bg-white" : "bg-[#F9FAFB]"
      )}
      id="testimonial"
    >
      <div className="bx-container">
        <Carousel plugins={[plugin.current]}>
          {/* COPY AND CONTROLS */}
          <div className="">
            <h5 className="text-[#667085] uppercase font-semibold block w-full">
              {t("title")}
            </h5>
            <div className="flex flex-wrap justify-between items-center mt-4 mb-8">
              <h2 className="text-xl md:text-3xl font-medium max-w-full lg:max-w-[50%] text-[#101828]">
                {t("subtitle")}{" "}
                <span className="text-[#F5BE01]">{t("highlihtedTitle")}</span>
              </h2>

              <div className="flex flex-wrap gap-2">
                <CarouselPrevious />
                <CarouselNext />
              </div>
            </div>
          </div>

          {/* TESTIMONIAL CARD */}

          <CarouselContent>
            {testimoni.map((it) => (
              <CarouselItem className="md:basis-1/2 lg:basis-1/3" key={it.name}>
                <TestimonialCard
                  image={it.image}
                  name={it.name}
                  message={it.message}
                  unit={it.unit}
                  mode={mode}
                />
              </CarouselItem>
            ))}
          </CarouselContent>

          <div className="mt-4 flex justify-center">
            <CarouselIndicator />
          </div>
        </Carousel>
      </div>
    </motion.section>
  );
}

const TestimonialCard: React.FC<TestimoniItemType> = ({
  image,
  message,
  name,
  unit,
  mode,
}) => {
  const isWhite = mode == "white";
  return (
    <div
      className={cn(
        "w-full md:max-w-[600px] h-[250px]  px-4 py-5 rounded-xl  p-6",
        isWhite ? "bg-white" : "bg-[#F9FAFB]"
      )}
    >
      <p className="text-sm text-[#344054] h-[50%] line-clamp-6 text-ellipsis font-medium mb-11">
        {message}
      </p>
      <div className="flex gap-5 items-center">
        <Image
          width={64}
          height={64}
          className="rounded-full"
          src={image}
          alt=""
        />
        <div className="flex-col">
          <p className="text-[#475467] text-md font-bold mb-1">{name}</p>
          <p className="text-[#667085] text-md font-regular">{unit}</p>
        </div>
      </div>
    </div>
  );
};
