"use client";
import Image from "next/image";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import heroBg from "../../../../public/assets/hero/hero-bg.png";
import heroBgMobile from "../../../../public/assets/hero/hero-bg-mobile.png";
import heroCard1 from "../../../../public/assets/hero/hero-card-1.png";
import heroCard2 from "../../../../public/assets/hero/hero-card-2.png";
import heroIconWifi from "../../../../public/assets/hero/hero-icon-wi-fi.png";

import { useTranslations } from "next-intl";

export default function Hero() {
  const t = useTranslations("Homepage.Hero");

  return (
    <section
      id="hero"
      className="text-[#101828] text-xs flex flex-col justify-between"
    >
      <div className="text-center bx-container">
        <motion.article
          initial={{ opacity: 0 }}
          viewport={{ once: true }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, type: "just" }}
        >
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-black  text-[#101828] md:mt-12">
            {t("title")}
          </h1>
          {/* DESCRIPTION */}
          <p className="my-6 md:my-8 text-base md:text-lg text-[#344054] font-bold">
            {t("subtitle")}
          </p>
        </motion.article>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          viewport={{ once: true }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.8,
            type: "spring",
            bounce: 0.4,
            delay: 0.02,
          }}
        >
          <Link
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=628112648133&text&type=phone_number&app_absent=0&text=Nama:%20%0AAlamat:%0APesan:%20saya%20ingin%20bergabung%20menjadi%20mitra%20BroilerX.%20Bagaimana%20caranya?%0A"
          >
            <Button variant={"gradient"} className="btn-comfortable">
              {t("btnGabungKemitraan")}
            </Button>
          </Link>
        </motion.div>
      </div>

      {/* <div className="bg-[url('/assets/bg-hero.png')] w-full bg-top bg-no-repeat max-h-[60vh] bg-contain mx-auto -mb-4 hidden lg:block max-w-[1920px]"></div> */}

      {/* ----------- */}
      {/* DESKTOP HERO */}
      {/* ----------- */}
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        viewport={{ once: true }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.99,
          type: "spring",
          bounce: 0.4,
          delay: 0.4,
        }}
        className="relative hidden lg:block pointer-events-none max-w-[1920px] mx-auto w-full"
      >
        <Image src={heroBg} priority alt="hero" />
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          viewport={{ once: true }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.99,
            type: "spring",
            bounce: 0.4,
            delay: 0.5,
          }}
          className="absolute top-[20%] left-[25%] max-w-36"
        >
          <Image src={heroCard1} alt="Card 1" priority />
        </motion.div>
        <div className="absolute top-[25%] right-[25%] ">
          <div className="relative">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              viewport={{ once: true }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 1,
                type: "spring",
                bounce: 0.4,
                delay: 0.6,
              }}
            >
              <Image
                src={heroIconWifi}
                alt="Card 2"
                priority
                className="absolute right-0 -top-16 w-28"
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              viewport={{ once: true }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.99,
                type: "spring",
                bounce: 0.4,
                delay: 0.5,
              }}
              className="max-w-52"
            >
              <Image src={heroCard2} alt="Card 2" priority />
            </motion.div>
          </div>
        </div>
      </motion.div>
      {/*  */}

      {/* MOBILE */}
      <motion.div
        initial={{ opacity: 0 }}
        viewport={{ once: true }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, type: "just" }}
        className="relative block lg:hidden pointer-events-none"
      >
        <Image
          src={heroBgMobile}
          className="mx-auto object-cover w-full"
          priority
          alt="hero"
        />
      </motion.div>
    </section>
  );
}
