"use client";
import Image from "next/image";
import { motion } from "framer-motion";

// @ts-ignore
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/react-splide/css";
import { useTranslations } from "next-intl";

const mitraRow1 = [
  // "/assets/partners/partner-1.png",
  "/assets/partners/partner-2.png",
  // "/assets/partners/partner-3.png",
  "/assets/partners/partner-4.png",
  "/assets/partners/partner-5.png",
  "/assets/partners/partner-6.png",
  "/assets/partners/partner-7.png",
  "/assets/partners/partner-8.png",
  // "/assets/partners/partner-9.png",
  "/assets/partners/partner-10.png",
  "/assets/partners/partner-11.png",
  "/assets/partners/partner-12.png",
  "/assets/partners/partner-13.png",
  // "/assets/partners/partner-14.png",
  "/assets/partners/partner-15.png",
  "/assets/partners/partner-16.png",
  "/assets/partners/partner-17.png",
  "/assets/partners/partner-18.png",
  // "/assets/partners/partner-19.png",
  "/assets/partners/partner-20.png",
  "/assets/partners/partner-40.png",
  "/assets/partners/partner-41.png",
  
];

const mitraRow2 = [
  "/assets/partners/partner-21.png",
  "/assets/partners/partner-22.png",
  "/assets/partners/partner-23.png",
  "/assets/partners/partner-24.png",
  "/assets/partners/partner-25.png",
  "/assets/partners/partner-26.png",
  "/assets/partners/partner-27.png",
  "/assets/partners/partner-28.png",
  "/assets/partners/partner-29.png",
  "/assets/partners/partner-30.png",
  "/assets/partners/partner-31.png",
  "/assets/partners/partner-32.png",
  "/assets/partners/partner-33.png",
  "/assets/partners/partner-34.png",
  "/assets/partners/partner-35.png",
  "/assets/partners/partner-36.png",
  "/assets/partners/partner-37.png",
  "/assets/partners/partner-38.png",
  "/assets/partners/partner-39.png",
  "/assets/partners/partner-42.png",
  "/assets/partners/partner-43.png",
];
export default function Partners() {
  const t = useTranslations("Homepage.Partner");

  return (
    <motion.section
      initial={{ opacity: 0 }}
      viewport={{ once: true }}
      whileInView={{ opacity: 1 }}
      className="my-10 p-10 text-[#101828] text-xs bg-[#F9FAFB]"
      id="partners"
    >
      <div className="bx-container">
        {/* HEAD */}
        <h2 className="text-xl md:text-3xl font-medium max-w-full text-center text-[#344054] my-4">
          {t("title")}
        </h2>

        <div className="flex flex-col gap-20 my-20 relative">
          <div className="absolute -left-10 h-full lg:w-[30%] bg-gradient-to-r from-[#F9FAFB] pointer-events-none to-transparent z-20"></div>
          <div className="absolute -right-10 h-full lg:w-[30%] bg-gradient-to-l from-[#F9FAFB] pointer-events-none to-transparent z-20"></div>

          <Splide
            aria-label="My Favorite Images"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              drag: "free",
              autoScroll: {
                speed: 1,
              },
              pagination: false,
              arrows: false,
              autoWidth: true,
              gap: 30,
            }}
          >
            {mitraRow1.map((t) => (
              <SplideSlide key={t}>
                <Image
                  width={160}
                  height={50}
                  src={t}
                  alt={t}
                  className="inline-block brightness-50 grayscale hover:brightness-100 hover:grayscale-0 hover:drop-shadow-lg transition-all ease-in-out duration-500"
                />
              </SplideSlide>
            ))}
          </Splide>

          <Splide
            aria-label="My Favorite Images"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              drag: "free",
              autoScroll: {
                speed: 1,
              },
              pagination: false,
              arrows: false,
              autoWidth: true,
              gap: 30,
              direction: "rtl",
            }}
          >
            {mitraRow2.map((t) => (
              <SplideSlide key={t}>
                <Image
                  width={160}
                  height={50}
                  src={t}
                  alt={t}
                  className="inline-block brightness-50 grayscale hover:brightness-100 hover:grayscale-0 hover:drop-shadow-lg transition-all ease-in-out duration-500"
                />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </motion.section>
  );
}
