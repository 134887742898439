"use client";
import { Button } from "@/components/ui/button";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";

export default function Info() {
  const t = useTranslations("Homepage.Info");

  return (
    <motion.section
      initial={{ opacity: 0 }}
      viewport={{ once: true }}
      whileInView={{ opacity: 1 }}
      className="my-10 bx-container text-[#101828] text-xs"
      id="informasi"
    >
      <div className="flex flex-wrap justify-center items-center">
        <div className="flex-auto inline-flex justify-center items-center">
          <Image
            width={366}
            height={309}
            src="/assets/seputar-ayam.png"
            alt="seputar ayam"
            className="mx-auto"
          />
        </div>

        <div className="flex-auto inline-flex flex-col items-start justify-center">
          <div className="mx-auto">
            <p className="text-xl md:text-2xl text-center md:text-left mt-4 line-clamp-3 lg:max-w-[70%]">
              {t("title")}
            </p>
            <div className="flex justify-center md:justify-start mt-4">
              <Link href={"/update-harga-ayam"}>
                <Button variant={"gradient"} className="btn-comfortable">
                  {t("btnLihatSelengkapnya")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
}
